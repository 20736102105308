import axiosApi from '@/api/index';

let urlPath = '/api/schedule';

async function get(params) {
	return axiosApi.get(`${urlPath}`, params);
}

async function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}

async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}

export default { get, getOne, remove };
