// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/location';

async function get(memoId) {
	return await axiosApi.get(`${urlPath}/${memoId}`);
}

export default { get };
