<template>
	<div :class="locationInfoPopLayer" id="layer01">
		<div class="inner" style="width:500px">
			<strong class="tit">위치 정보</strong>
			<div class="cont">
				<div class="locationList">
					<ul>
						<li v-for="(location, index) in locationList" :key="index">
							<div class="location">
								<span @click="onBuilding(building.id)" style="cursor: pointer;">{{ building.name }}</span>
								<span v-if="location.floorId" @click="onFloor(location.floorId)" style="cursor: pointer;">{{
									location.floorName
								}}</span>
								<span
									v-if="location.roomId"
									@click="location.shapeType == 'all' ? null : onRoom(location.roomId)"
									:style="location.shapeType == 'all' ? null : 'cursor: pointer;'"
									>{{ location.roomName }}</span
								>
								<span v-if="location.objectPinId">{{ location.objectName }}</span>
							</div>
						</li>
					</ul>
				</div>
				<!-- btnArea -->
				<div class="btnArea">
					<button type="button" class="btn fn" @click="onCloseLocationInfo">닫기</button>
				</div>
				<!--// btnArea -->
			</div>
			<a href="javascript:void(0)" name="layer01" @click="onCloseLocationInfo">닫기</a>
		</div>
	</div>
</template>
<script>
import location from '@/api/location';
import building from '@/api/building';
export default {
	data() {
		return {
			locationList: [],
			locationInfoPopLayer: 'layerPop locationInfoPop',
			building: null,
		};
	},
	methods: {
		async onOpenLocationInfo(memoId, buildingId) {
			await this.onGetLocationInfo(memoId, buildingId);
			this.locationInfoPopLayer = 'layerPop locationInfoPop on';
		},
		async onGetLocationInfo(memoId, buildingId) {
			try {
				this.building = await building.getOne(buildingId);
				let res = await location.get(memoId);
				this.locationList = res.data;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		onCloseLocationInfo() {
			this.locationList = [];
			this.building = null;
			this.locationInfoPopLayer = 'layerPop locationInfoPop';
		},
		onBuilding(buildingId) {
			this.$router.push({ name: 'buildingDetail', params: { id: buildingId } });
		},
		onFloor(floorId) {
			this.$router.push({ name: 'floorDetail', params: { id: floorId } });
		},
		onRoom(roomId) {
			this.$router.push({ name: 'roomDetail', params: { id: roomId } });
		},
	},
};
</script>
<style scoped>
.locationList ul li {
	position: relative;
	padding: 0px 10px 0px 10px;
	border-radius: 8px;
	background: #fff;
}
.locationList ul li + li {
	margin-top: 20px;
}
.location > span {
	float: left;
	line-height: 52px;
	font-size: 15px;
	color: #0c234c;
	font-weight: 500;
}
</style>
