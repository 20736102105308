<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">메모상세</h2>
				<a @click="moveBack" class="btnBack">메모 목록으로 이동</a>
				<div class="right">
					<a @click="memoUpdate()" class="btnIcoWeite" v-if="userId === creator">글수정</a>
					<a @click="onRemove()" class="btnTrash" v-if="userId === creator">삭제</a>
				</div>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- memoDetail -->
				<div class="memoDetail">
					<!-- location -->
					<div class="location">
						<span @click="onBuilding(buildingId)" style="cursor: pointer;">{{ buildingName }}</span>
						<span v-if="floorId != null" @click="onFloor(floorId)" style="cursor: pointer;">{{ floorName }}</span>
						<span
							v-if="room"
							@click="room.shapeType == 'all' ? null : onRoom(roomId)"
							:style="room.shapeType == 'all' ? null : 'cursor: pointer;'"
							>{{ roomName }}</span
						>
						<span v-if="objectPinId != null">{{ objectName }}</span>
						<span v-if="locations && locations.length > 1" class="locationCount"
							>외 {{ locations.length - 1 }}개 <span class="locationInfo" @click="onLocationInfo"></span
						></span>
					</div>
					<!--// location -->
					<!-- memo -->
					<div class="memo">
						<div class="user">
							<div class="imgArea">
								<img v-if="creatorImageId" :src="`/file/${creatorImageId}`" alt="" />
							</div>
							<p>{{ creatorName }}</p>
						</div>
						<div
							class="user"
							v-if="project != null"
							@click="onProjectMemoList()"
							style="cursor: pointer;display:block;"
						>
							<span><strong>[프로젝트]</strong></span>
							<span style="white-space:normal;">&nbsp;{{ project.name }}</span>
							<span class="interval">
								&nbsp;({{ project.beginDate | dateFormatYear }}~{{ project.endDate | dateFormatYear }})
							</span>
						</div>
						<div class="user" style="display:block;" v-if="schedule">
							<span>
								<strong>[스케줄]</strong>
							</span>
							<span style="white-space:normal;">&nbsp;{{ schedule.name }}</span>
							<span class="interval"
								>&nbsp;({{ setIntervalStr(schedule.intervalType) }} {{ setDateFormat(schedule) }})</span
							>
						</div>
						<p class="date">
							<span>{{ createDate | dateFormatYear }}</span>
							<span>{{ createDate | dateFormatMonth }}</span>
						</p>
						<p>
							<span>
								<pre v-if="content" style="font-family: 'Noto Sans KR', sans-serif;">{{
									content.replace(/ /g, '&nbsp;')
								}}</pre>
							</span>
						</p>
					</div>
					<!--// memo -->
					<!-- imgList -->
					<div class="devImgList">
						<div class="inBox">
							<p v-for="(image, index) in images" :key="index">
								<img
									:src="`/file/${image.fileId}`"
									alt=""
									@click="onOpenImage(image.fileId)"
									style="cursor: pointer;"
								/>
							</p>
						</div>
					</div>
					<!--// imgList -->
					<!-- attaArea -->
					<div class="attaArea">
						<p v-for="(file, index) in files" :key="index">
							<a :href="`/file/download/${file.fileId}`">{{ file.fileName }}</a>
						</p>
					</div>
					<!--// attaArea -->
				</div>
				<!--// memoDetail -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- contents -->
		<div class="contents">
			<!-- commentArea -->
			<div class="commentArea">
				<!-- viewInfo -->
				<div class="viewInfo">
					<a href="" class="btnComment">{{ comment.total }}</a>
					<a href="" class="btnViews">{{ views }}</a>
				</div>
				<!--// viewInfo -->
				<!-- writeArea -->
				<div class="writeArea">
					<textarea title="댓글 입력" :placeholder="placeholder" v-model="comment.content" maxlength="254"></textarea>
					<div class="btnRight">
						<button type="button" class="btn ss" @click="onSaveComment()" :disabled="!saveBtn">등록하기</button>
					</div>
				</div>
				<!--// writeArea -->
				<!-- list -->
				<div class="list">
					<ul>
						<li class="memo" v-for="(comment, index) in commentList" :key="index">
							<div class="user">
								<div class="imgArea">
									<img v-if="comment.creatorImageId" :src="`/file/${comment.creatorImageId}`" alt="" />
								</div>
								<p>{{ comment.creatorName }}</p>
							</div>
							<p class="date">
								<span>{{ comment.createDate | dateFormatYear }}</span>
								<span>{{ comment.createDate | dateFormatMonth }}</span>
							</p>
							<p v-if="comment.editMode == false">
								<span>
									<pre v-if="comment.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
										comment.content.replace(/ /g, '&nbsp;')
									}}</pre>
								</span>
							</p>
							<textarea
								v-else
								title="수정 댓글 입력"
								placeholder="수정할 댓글을 입력해주세요."
								v-model="updateComment"
								maxlength="254"
							></textarea>
							<div class="btnRight" v-if="userId === comment.creator">
								<button type="button" class="btn ss btnGrayL" @click="onUpdateComment(index)">
									수정하기
								</button>
								<button type="button" class="btn ss btnDGray" @click="onDeleteComment(comment.id)">
									삭제하기
								</button>
							</div>
						</li>
					</ul>
				</div>
				<div class="btnFriArea">
					<div class="inBox">
						<a class="btnEdit" v-if="friendAuth == true" @click="onMemoRegist()">글쓰기</a>
					</div>
				</div>
				<!--// list -->
			</div>
			<!--// commentArea -->
		</div>
		<!--// contents -->
		<PopUpImage ref="popUpImage"></PopUpImage>
		<PopUpLocationInfo ref="popUpLocationInfo" />
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner, getAuth, dateFormatYear, dateFormatMonth, setIntervalStr } from '@/utils/cmm';
import memo from '@/api/memo';
import memoFile from '@/api/memoFile';
import memoComment from '@/api/memoComment';
import PopUpImage from '../popup/popUpImage';
import PopUpLocationInfo from '../popup/popUpLocationInfo';
import building from '@/api/building';
import friend from '@/api/friend';
import room from '@/api/room';
import schedule from '@/api/schedule';
import project from '../../api/project';

export default {
	components: { PopUpImage, PopUpLocationInfo },
	created() {
		if (!this.$route.params.id) {
			this.$router.push({ name: 'buildingList' });
			return false;
		}
		this.onGet();
		this.onGetComment();
	},
	mounted() {
		this.$EventBus.$on('paging', () => {
			if (this.comment.total <= this.comment.offset) return;
			this.onGetComment();
		});
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
	},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	watch: {
		'$route.params.id'() {
			this.images = [];
			this.files = [];
			this.commentList = [];
			this.comment.offset = 0;
			this.comment.total = 0;
			this.onGet();
			this.onGetComment();
		},
	},
	data() {
		return {
			id: '',
			title: '',
			content: '',
			creator: '',
			creatorName: '',
			creatorImageId: '',
			buildingId: null,
			floorId: null,
			roomId: null,
			objectPinId: null,
			room: null,
			buildingName: '',
			floorName: '',
			roomName: '',
			objectName: '',
			views: '',
			createDate: '',
			projectId: null,
			images: [],
			files: [],
			mine: false,
			userId: getAuth().id,
			commentList: [],
			comment: {
				offset: 0,
				limit: 10,
				total: '',
				content: '',
			},
			updateComment: null,
			placeholder: '댓글을 입력해주세요.',
			locationCount: 0,
			locations: null,
			friendAuth: false,
			scheduleId: null,
			schedule: null,
			project: null,
		};
	},
	computed: {
		saveBtn: {
			get() {
				return this.comment.content.length > 0;
			},
			set() {},
		},
	},
	methods: {
		async onGet() {
			this.id = this.$route.params.id;
			try {
				showSpinner();
				let res = await memo.getOne(this.id);
				this.title = res.title;
				this.content = res.content;
				this.creator = res.creator;
				this.creatorName = res.creatorName;
				this.creatorImageId = res.creatorImageId;
				this.views = res.views;
				this.createDate = res.createDate;
				this.buildingId = res.buildingId;
				this.floorId = res.floorId;
				this.roomId = res.roomId;
				this.objectPinId = res.objectPinId;
				this.buildingName = res.buildingName;
				this.floorName = res.floorName;
				this.roomName = res.roomName;
				this.objectName = res.objectName;
				this.locations = res.locations;
				this.scheduleId = res.scheduleId;
				this.projectId = res.projectId;
				this.mine = getAuth().id === res.creator;
				let params = {
					id: this.id,
					type: 'I',
				};
				let imageRes = await memoFile.get(params);
				if (imageRes.total > 0) this.images = imageRes.data;

				params.type = 'F';
				let fileRes = await memoFile.get(params);
				if (fileRes.total > 0) this.files = fileRes.data;
				await this.friendInfo();
				if (this.roomId != null) {
					this.room = await room.getOne(this.roomId);
				}
				if (this.scheduleId) {
					this.schedule = await schedule.getOne(this.scheduleId);
				}

				if (this.projectId) {
					this.project = await project.getOne(this.projectId);
				} else {
					this.project = null;
				}
			} catch (ex) {
				await this.$alert(`${ex.message}`);
				this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
		async onRemove() {
			if (!(await this.$confirm('선택하신 메모를 삭제하시겠습니까?', '메모 안내'))) return false;
			try {
				showSpinner();
				await memo.remove(this.id);
				this.$router.go(-1);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onGetComment() {
			try {
				let params = {
					offset: this.comment.offset,
					limit: this.comment.limit,
				};
				let res = await memoComment.get(this.id, params);
				this.comment.total = res.total;
				if (this.comment.total > 0) {
					res.data.forEach(c => {
						c.editMode = false;
						this.commentList.push(c);
					});
					this.comment.offset += this.comment.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async onSaveComment() {
			let params = {
				memoId: this.id,
				content: this.comment.content,
			};
			try {
				showSpinner();
				await memoComment.create(params);
				this.comment.content = '';
				this.commentList = [];
				this.comment.offset = 0;
				this.onGetComment();
			} catch (ex) {
				await this.$alert(`${ex.message}`);
				if (ex.status == 2013) {
					this.moveBack();
				}
			} finally {
				hideSpinner();
			}
		},
		async onDeleteComment(id) {
			if (!(await this.$confirm('선택하신 댓글을 삭제하시겠습니까?', '메모 안내'))) return false;
			try {
				showSpinner();
				await memoComment.remove(id);
				this.commentList = [];
				this.comment.offset = 0;
				this.onGetComment();
			} catch (ex) {
				await this.$alert(`${ex.message}`);
				if (ex.status == 2013) {
					this.moveBack();
				}
			} finally {
				hideSpinner();
			}
		},
		async onUpdateComment(index) {
			if (this.commentList[index].editMode == false) {
				this.commentList.forEach(c => (c.editMode = false));
				this.commentList[index].editMode = true;
				this.updateComment = this.commentList[index].content;
			} else {
				try {
					showSpinner();
					let params = {
						id: this.commentList[index].id,
						content: this.updateComment,
					};
					await memoComment.update(params);
					this.commentList[index].editMode = false;
					this.commentList[index].content = this.updateComment;
				} catch (ex) {
					await this.$alert(`${ex.message}`);
					if (ex.status == 2013) {
						this.moveBack();
					}
				} finally {
					hideSpinner();
				}
			}
		},
		memoUpdate() {
			this.$router.push({
				name: 'memoUpdate',
				params: {
					memoId: this.id,
					reqUrl: this.$route.params.reqUrl,
					buildingId: this.$route.params.buildingId,
					projectId: this.$route.params.projectId,
					name: this.$route.params.name,
				},
			});
		},
		moveBack() {
			if (this.$route.params.reqUrl == 'mine') {
				this.$router.push({ name: 'manageMemo' });
				return;
			} else if (this.$route.params.reqUrl == 'buildingDetail') {
				this.$router.push({
					name: 'buildingDetail',
					params: { id: this.buildingId, flag: this.$route.params.flag, targetDate: this.$route.params.targetDate },
				});
				return;
			} else if (this.$route.params.reqUrl == 'room') {
				if (this.room.shapeType === 'all') {
					this.$router.push({ name: 'floorDetail', params: { id: this.floorId } });
					return;
				}
				this.$router.push({ name: 'roomDetail', params: { id: this.roomId } });
				return;
			} else if (this.$route.params.reqUrl == 'floor') {
				this.$router.push({ name: 'floorDetail', params: { id: this.floorId } });
				return;
			} else if (this.$route.params.reqUrl == 'building') {
				this.$router.push({ name: 'buildingList' });
				return;
			} else if (this.$route.params.reqUrl == 'projectMemoList') {
				this.$router.push({
					name: 'projectMemoList',
					params: {
						buildingId: this.$route.params.buildingId,
						id: this.$route.params.projectId,
						name: this.project.name,
					},
				});

				return;
			} else if (this.$route.params.reqUrl == 'memoList') {
				this.$router.push({
					name: 'memoList',
					params: {
						buildingId: this.buildingId,
						floorId: this.floorId,
						roomId: this.roomId,
						objectPinId: this.objectPinId,
					},
				});
			} else {
				this.$router.go(-1);
			}
		},
		onBuilding(buildingId) {
			this.$router.push({ name: 'buildingDetail', params: { id: buildingId } });
		},
		onFloor(floorId) {
			this.$router.push({ name: 'floorDetail', params: { id: floorId } });
		},
		onRoom(roomId) {
			this.$router.push({ name: 'roomDetail', params: { id: roomId } });
		},
		onOpenImage(imageId) {
			this.$refs.popUpImage.onOpenImage(imageId, this.images);
		},
		onMemoRegist() {
			this.$router.push({
				name: 'memoRegist',
				params: {
					buildingId: this.buildingId,
					floorId: this.floorId,
					roomId: this.roomId,
					objectPinId: this.objectPinId,
					projectId: this.projectId,
					locations: this.locations,
					reqUrl: this.$route.params.reqUrl,
				},
			});
		},
		async friendInfo() {
			let res = await building.getOne(this.buildingId);
			if (getAuth().id != res.owner) {
				let params = {
					buildingId: this.buildingId,
					userId: res.owner,
					friendId: getAuth().id,
					state: 'F',
				};
				let res2 = await friend.getOne(params);
				if (res2.authority == 'A') this.friendAuth = true;
			} else {
				this.friendAuth = true;
			}
		},
		async onLocationInfo() {
			await this.$refs.popUpLocationInfo.onOpenLocationInfo(this.id, this.buildingId);
		},
		async onProjectMemoList() {
			if (await this.$confirm('프로젝트 목록으로 이동하시겠습니까?', '프로젝트 안내')) {
				this.$router.push({ name: 'projectMemoList', params: { buildingId: this.buildingId, id: this.projectId } });
				return;
			}
		},
		setIntervalStr(intervalType) {
			return setIntervalStr(intervalType);
		},
		setDateFormat(schedule) {
			let date = schedule.beginDate;
			switch (schedule.intervalType) {
				case 'Y':
					date = this.$moment(date).format('M월 D일');
					break;
				case 'M':
					date = this.$moment(date).format('D일');
					break;
				case 'W':
					switch (this.$moment(date).day()) {
						case 0:
							date = '일요일';
							break;
						case 1:
							date = '월요일';
							break;
						case 2:
							date = '화요일';
							break;
						case 3:
							date = '수요일';
							break;
						case 4:
							date = '목요일';
							break;
						case 5:
							date = '금요일';
							break;
						case 6:
							date = '토요일';
							break;
					}
					break;
				case 'D':
					date = this.$moment(date).format('YYYY월 M월 D일');
					break;
			}
			return date;
		},
	},
};
</script>
<style scoped>
.locationCount {
	padding-left: 5px;
	background: none;
}
.locationInfo {
	padding: 0px 15px 0px 15px;
	color: #000;
	background: url('/resources/images/bl-arrow.png') no-repeat right 11px center;
	cursor: pointer;
}
</style>
